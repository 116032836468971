import classNames from 'classnames';
import { useEffect, useState } from 'react';

import FullForm from './NewLanding/FullForm';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  justify-content: center;
  align-items: center;
  background: var(--auth-required-bg-desktop);

  @media (max-width: 776px) {
    background: var(--auth-required-bg-mobile);

    margin-top: 0px;
  }
`;

const OnlyForm = () => {
  const [showRegBlock, setShowRegBlock] = useState(false);

  useEffect(() => {
    const isOpen = window?.settings?.is_form_open;

    if (isOpen) {
      setShowRegBlock(true);
    }
  }, []);

  return (
    <Container
      className={classNames('roulette-section', {
        'roulette-section--show-form': showRegBlock,
      })}
    >
      {showRegBlock && <FullForm setShowRegBlock={setShowRegBlock} />}
    </Container>
  );
};

export default OnlyForm;
