import { translateField } from '../../shared/utils';
import { Auth } from '../../components/Auth/Auth';
import { ButtonLanguages, ButtonTypes } from '../../elements';
import ReactInlineSvg from 'react-inlinesvg';
import styled from 'styled-components';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

const Modal = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;

  //background-color: var(--background-modal);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Cross = styled.div`
  position: absolute;
  right: 30px;
  top: 5px;
  width: 10px;
  height: 10px;
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 23px;
    width: 2px;
    background-color: var(--cross-color);
    box-shadow: var(--cross-shadow);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media (min-width: 921px) {
    top: 5px;
  }
`;

const FullForm = ({ setShowRegBlock }) => {
  const words = useSelector((state) => state.words);
  const size = useSelector((state) => state.handling.size);

  const wheelData = [
    { option: translateField('fortune_option_1', 'wheel.fortune', words, false), key: 'fortune_option_1', style: { backgroundColor: '#7a0000' } },
    { option: translateField('fortune_option_2', 'wheel.fortune', words, false), key: 'fortune_option_2', style: { backgroundColor: '#151520' } },
    { option: translateField('fortune_option_3', 'wheel.fortune', words, false), key: 'fortune_option_3', style: { backgroundColor: '#7a0000' } },
    { option: translateField('fortune_option_4', 'wheel.fortune', words, false), key: 'fortune_option_4', style: { backgroundColor: '#151520' } },
    { option: translateField('fortune_option_5', 'wheel.fortune', words, false), key: 'fortune_option_5', style: { backgroundColor: '#7a0000' } },
    { option: translateField('fortune_option_6', 'wheel.fortune', words, false), key: 'fortune_option_6', style: { backgroundColor: '#151520' } },
    { option: translateField('fortune_option_7', 'wheel.fortune', words, false), key: 'fortune_option_7', style: { backgroundColor: '#7a0000' } },
    { option: translateField('fortune_option_8', 'wheel.fortune', words, false), key: 'fortune_option_8', style: { backgroundColor: '#151520' } },
  ];

  const prizeIndexes = [1, 2];
  const bonuses = window?.settings?.bonuses_on_top ? prizeIndexes.map((number) => wheelData[number]) : [];
  const ref = useRef(null);

  return (
    <Modal>
      <ModalWrapper ref={ref}>
        <div className="show-form">
          <img className="logo" src="/logo.png" alt="logo" />

          <div className="show-form_content">
            <Cross onClick={() => setShowRegBlock(false)} className="close" />

            {bonuses.length > 0 && (
              <div className="roulette-section__bonus-in-form">
                <div className="title">{translateField('congratulations', 'roulette', words, false)}</div>
                <div className="title-win">{translateField('u_win_bonuses', 'roulette', words, false)}</div>
                <div className="bonus-in-form">
                  {bonuses.map(({ key }, i) => (
                    <div key={i} className="roulette-section__bonus item">
                      <span>{translateField(key, 'wheel.fortune', words, true)}</span>
                    </div>
                  ))}
                </div>
                <div className="title-reg">{translateField('complete_registration', 'roulette', words, false)}</div>
              </div>
            )}

            <Auth />
          </div>
          <ButtonTypes
            className="roulette-section__link"
            location="roulette-section__link-site"
            onClick={() => window.open(translateField('redirect_link', 'roulette', words, false), '_self')}
          >
            <span>{translateField('have_account', 'roulette', words, false)}</span>
            <span>{translateField('to_site', 'roulette', words, false)}</span>
            <ReactInlineSvg className="icon-arrow" src="/images/icons/arrow-right.svg" alt="to_login" width="10" height="10" />
          </ButtonTypes>
          {!size.mobile && <ButtonLanguages />}
        </div>
      </ModalWrapper>
    </Modal>
  );
};

export default FullForm;
